html,
body {
  margin: 0;
  background: #191919;
  color: #fff;
  font-family: "Roboto Mono";
  user-select: none;
}

nav {
  background: #292929;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/RobotoMono-Regular.ttf");
}

select {
  background: #252525;
}

button {
  background: #252525;
}

button:hover:not(:disabled) {
  background-color: #313131;
}

.light-bg {
  background: #1c1c1c;
}

.tooltip {
  opacity: 1 !important;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.saturation-white {
  background: -webkit-linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}
.saturation-black {
  background: -webkit-linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
}

.hue {
  background: linear-gradient(
    -90deg,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
  background: -webkit-linear-gradient(
    -90deg,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
}

.absolute-stretch {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.progress {
  border-radius: 999px;
  background: white;
  position: relative;
  clip-path: inset(0 0 0 0);
}

.progress::before {
  position: absolute;
  border-radius: 999px;
  width: 20%;
  height: 100%;
  left: -20%;
  top: 0;
  background: rgb(0, 115, 255);
  content: "";
  animation: infinite 1.5s progress_anim linear;
}

@keyframes progress_anim {
  from {
    left: -20%;
  }
  to {
    left: 100%;
  }
}

.fall-enter {
  opacity: 0;
  transform: scale(1.1);
}

.fall-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fall-exit {
  opacity: 1;
  transform: scale(1);
}

.fall-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.custom-select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
